<template>
  <div>
    <v-layout justify-center align-center wrap="true" style="width: 100vw">
      <p class="overline" style="display: block">Your Books</p>
      <div class="list-container">
        <BookCard :instrument="book.instrument"
                  :created-on="book.date"
                  :book-name="book.name"
                  :manager="book.author.email"
                  :bookId="book.id"
                  :offline="true"
                  :color="`rgba(${book.color.r},${book.color.g},${book.color.b},${book.color.a})`"
                  v-for="book in this.books" :key="book.id"
        ></BookCard>
      </div>
    </v-layout>
    <v-btn
        dark
        fab
        class="deleteButton"
        @click="deleteEverything()"
    >
      <v-icon>mdi-delete</v-icon>
    </v-btn>

  </div>
</template>

<script>
import BookCard from "../components/BookCard";
import useIndexedDB from "../scripts/dbHandler";
import Swal from "sweetalert2";

export default {
  name: "Overview",
  components: { BookCard },
  async created() {
    this.loadBooks();
  },
  data() {
    return {
      books: [],
    }
  },
  methods: {
    async deleteEverything() {
      Swal.fire({
        icon: "warning",
        title: 'Do you want to delete all downloaded files to free up space?',
        showCancelButton: true,
        confirmButtonText: `Yes`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.OIndexedDB || window.msIndexedDB;
          indexedDB.deleteDatabase("backupFiles");
          Swal.fire('Deleted!', '', 'success').then(()=>{
            this.$router.push({name:"login"});
          });
        }
      })
    },
    async loadBooks() {
      await useIndexedDB((db) => {
        try {
          let transaction = db.transaction(["books"], "readonly");
          transaction.objectStore("books").get("bookList").onsuccess = (event) => {
            this.books = event.target.result;
            //[...new Set(event.target.result.map((sheet)=>sheet.bookName))]
          };

        } catch (e) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `Database is empty or does not exist! Fix this by logging in and pressing the backup-button!`
          })
        }
      });
    },
  }
}
</script>

<style scoped>
.list-container {
  justify-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
}
.deleteButton {
  position: fixed;
  bottom: 4rem;
  z-index: 3;
  right: 10px;
  background-color: #424242 !important;
}

.bookCard {
  margin: 1rem;
}
</style>