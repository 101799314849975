<template>
  <v-list-item
      @click="$emit('click')"
      style="width: 56px !important; max-width: 56px;"
      link
  >
      <v-list-item-icon style="margin-left: 0 !important;">
        <v-icon>{{ icon }}</v-icon>
      </v-list-item-icon>
  </v-list-item>
</template>

<script>
export default {
  name: "OptionListItem",
  props: {
    icon: String,
    title: String
  }
}
</script>

<style scoped>

</style>