/* eslint-disable no-console */

import {register} from 'register-service-worker'
import Swal from "sweetalert2";

const notifyUserAboutUpdate = worker => {
    Swal.fire({
        title: 'Installation of the app possible',
        showDenyButton: true,
        confirmButtonText: `Reload`,
        denyButtonText: `Maybe later`,
    }).then((result) => {
        if (result.isConfirmed)
            worker.postMessage({action: "skipWaiting"});
    })
}
register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
        console.log(
            'App might be served from cache by a service worker.\n' +
            'For more details, visit https://goo.gl/AFskqB'
        )
    },
    registered() {
        console.log('Service worker has been registered.')
    },
    cached() {
        console.log('Content has been cached for offline use.')
    },
    updatefound() {
        console.log('New content is downloading.')
    },
    updated(registration) {
        console.log('New content is available; please refresh.')
        notifyUserAboutUpdate(registration.waiting)
    },
    offline() {
        console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
        console.error('Error during service worker registration:', error)
    }
});

var refreshing;
navigator.serviceWorker.addEventListener("controllerchange", function () {
    if (refreshing) return;
    window.location.reload();
    refreshing = true;
})
