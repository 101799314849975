<template>
  <div class="presenterMode" id="app">
    <v-app id="inspire">
      <v-card>
        <v-navigation-drawer
            v-model="drawer"
            :mini-variant="true"
            permanent
            right
            fixed
        >
          <v-list-item class="px-2">
            <div style="margin-left: .5rem">
              {{ (this.imageId + 1) + "/" + this.getPageCount }}
            </div>
          </v-list-item>
          <v-divider></v-divider>
          <v-list dense>
            <v-list-item
                v-for="item in items"
                :key="item.title"
                @click="item.clickHandler()"
                link
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-card>
      <div style="width: calc(100vw - 56px)" :class="{noOverloadHeight:fitHeight}">
        <img :class="{fitScreen:fitImage}" :src="imageUrl"/>
      </div>
    </v-app>
  </div>
</template>
<script>

import base64url from "base64url";
import {EventBus} from "../event-bus";
import {mapGetters} from "vuex";

export default {
  name: "Sheet",
  mounted() {
    EventBus.$emit("reloadHeader");
    this.setImageUrl();
  },
  data() {
    return {
      drawer: true,
      mini: true,
      fitImage: true,
      fitHeight: true,
      items: [
        {title: 'Home', icon: 'mdi-home', clickHandler: () => {
          if(this.getBookId === "online-search")
            this.$router.push({name: 'my-books', params: {showSearch: true}});
          else
            this.$router.push(`/book/${this.getBookId}`)
          }},
        {
          title: 'Next Page', icon: 'mdi-arrow-right', clickHandler: () => {
            if (this.imageId + 1 < this.getPageCount) {
              this.imageId++;
              this.setImageUrl();
            }
          }
        },
        {
          title: 'Previous Page', icon: 'mdi-arrow-left', clickHandler: () => {
            if (this.imageId > 0) {
              this.imageId--;
              this.setImageUrl();
            }
          }
        },
        {
          title: 'Stretch to page', icon: 'mdi-stretch-to-page', clickHandler: () => {
            this.fitImage = !this.fitImage;
          }
        },
        {
          title: 'Allow vertical scrolling', icon: 'mdi-arrow-vertical-lock', clickHandler: () => {
            this.fitHeight = !this.fitHeight;
          }
        },
        {
          title: 'Change full screen', icon: 'mdi-overscan', clickHandler: () => {
            if((window.fullScreen) || (window.innerWidth === screen.width && window.innerHeight === screen.height))
              document.exitFullscreen();
            else {
              const docelem = document.documentElement;

              if (docelem.requestFullscreen) {
                docelem.requestFullscreen();
              }
              else if (docelem.mozRequestFullScreen) {
                docelem.mozRequestFullScreen();
              }
              else if (docelem.webkitRequestFullscreen) {
                docelem.webkitRequestFullscreen();
              }
              else if (docelem.msRequestFullscreen) {
                docelem.msRequestFullscreen();
              }
            }
          }
        }
      ],
      sheetId: window.location.href.substring(window.location.href.lastIndexOf('/') + 1),
      imageId: 0,
      imageUrl: ""
    }
  },
  methods: {
    setImageUrl() {
      this.imageUrl = `https://sheetmusic.martin-dallinger.me/sheet/${window.location.href.substring(window.location.href.lastIndexOf('/') + 1)}/${this.imageId}?token=${base64url(localStorage.getItem("apollo-token"))}`
    }
  }
  ,
  computed: {
    ...
        mapGetters([
          'getPageCount',
          'getBookId',
        ])
  }
  ,
}
</script>

<style scoped>
body {
  padding: 0;
  margin: 0;
}
.fitScreen{
  object-fit: contain;
}

img {
  height: 100%;
  width: 100%;
}
</style>
<style>
.noOverloadHeight{
  height: 100vh !important;
}
.container {
  margin: 0 !important;
  padding: 0 !important;
}

::-webkit-scrollbar {
  display: none;
}
</style>