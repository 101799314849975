<template>
  <v-form v-model="valid" ref="form">
    <div style="padding: 1rem; display: grid; place-items: center; width: 100vw;">
    <v-container>
      <v-row>
        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              v-model="bookName"
              :rules="nameRules"
              :counter="20"
              label="Book name"
              required
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              v-model="instrument"
              :rules="nameRules"
              :counter="20"
              label="Instrument name"
              required
          ></v-text-field>
        </v-col>

      <v-col
          cols="12"
          md="4"
      >
        <v-select
            :items="associations"
            item-text="name"
            item-value="id"
            label="Association"
            v-model="selectedAssoc"
            :rules="select"
        ></v-select>
      </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="4"
        >
          <v-color-picker
              dot-size="22"
              swatches-max-height="172"
              hide-inputs
              v-model="color"
          ></v-color-picker>
        </v-col>
        <v-col cols="0" md="4"></v-col>
        <v-col
            cols="12"
            md="4"
        >
          <v-btn
              style="float: right"
              outlined
              rounded
              color="var(--primary)"
              text
              @click="handleAddBook"
          >
            Create
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    </div>
  </v-form>
</template>

<script>
import gql from "graphql-tag";
import Swal from "sweetalert2";

export default {
  name: "AddBookForm",
  data: () => ({
    valid: false,
    bookName: '',
    instrument: '',
    selectedAssoc: Object,
    nameRules: [
      v => !!v || 'Name is required',
      v => v.length <= 20 || 'Name must be less than 20 characters',
    ],
    select: [(v) => !!v || "Item is required"],
    color: {
      r:255,
      g:0,
      b:0,
      a:1
    },
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    associations: []
  }),
  async mounted() {
     this.$apollo.query({ fetchPolicy: 'no-cache',
      query: gql`query {
              user {
                associations{
                    id
                    name
                  }
                }
              }`
    }).then((result)=>{
      this.associations = result.data.user.associations;
      console.log(this.associations);
    })
  },
  methods: {
    async handleAddBook(){
      try {
        if(!this.$refs.form.validate())
          throw Error("Could not validate the form");
        await this.$apollo.mutate({
          mutation: gql`mutation ($bookName: String!,  $instrumentName: String!, $r: Int!,$g: Int!,$b: Int!,$a: Int!, $assocId: String!) {
                            addBook(book:{
                              name:$bookName,
                              instrument:$instrumentName,
                              color:{r:$r,g:$g,b:$b,a:$a},
                              association:{id: $assocId}
                      }){id}
                    }`,
          variables: {
            bookName: this.bookName,
            instrumentName: this.instrument,
            r: this.color.r,
            g: this.color.g,
            b: this.color.b,
            a: this.color.a,
            assocId: this.selectedAssoc
          },
        });
        Swal.fire({
          icon: 'success',
          title: 'Successfully added your book',
          text: `Go back to the "My Books" page to check it out!`
        });
        this.$emit("book-change");
        } catch (e) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `Wrong account data for user ${this.email} - try again!`
          });
          console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>