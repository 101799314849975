<template>
  <v-card
      class="associationCard"
      max-width="344"
      outlined
      style="display: block; margin: 1rem; min-width: min(20rem, 100vw);"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          {{ placeName }}
        </div>
        <v-list-item-title class="text-h5 mb-1">
          {{ associationName }}
        </v-list-item-title>
        <v-list-item-subtitle>Registered members: {{ numberOfMembers }}</v-list-item-subtitle>
        <v-list-item-subtitle>Free storage: {{ freeStorage }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-actions>
      <v-btn
          color="red"
          outlined
          rounded
          v-on:click="alert('not yet implemented... sry')"
          text
          disabled
      >
        Leave
      </v-btn>
      <v-btn
          color="green"
          outlined
          rounded
          v-on:click="share"
          text
      >
        Invite
      </v-btn>
    </v-card-actions>
  </v-card>

</template>

<script>
import Swal from "sweetalert2";
import gql from "graphql-tag";

export default {
  name: "AssociationCard",
  props: {
    placeName: String,
    associationName: String,
    numberOfMembers: Number,
    freeStorage: String,
    associationId: String
  },
  methods: {
    async share() {

      const shareResponse = await this.$apollo.mutate({
            mutation: gql`mutation ($associationId: String!) {
             generateInviteToken(associationId: $associationId)
          }`,
            // Parameters
            variables: {
              associationId: this.associationId
            }
          });
      const shareLink = `https://sheetmusic.martin-dallinger.me/invitation/${shareResponse.data.generateInviteToken}`;
      if(navigator.share)
        navigator
            .share({
              title: `Invitation to ${this.associationName}`,
              text: `Hey 👋 - I'd love to invite you to use Project Eagle where you can manage your sheet music and access the ones of your colleagues!`,
              url: shareLink
            })
            .then(() => console.log('Successful share! 🎉'))
            .catch(err => console.error(err));
      else{
        Swal.fire({
          icon: "info",
          title: 'Share me',
          html: `To invite others, please send this link which can be used once: <a href="${shareLink}">${shareLink}</a>`
        });
      }
    }
  }
}
</script>

<style scoped>
</style>