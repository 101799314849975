import base64url from "base64url";

export default function storeImages(sheets, books) {
    return new Promise(resolveOuter => {

    // IndexedDB
    let indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.OIndexedDB || window.msIndexedDB,
        dbVersion = 1.0;

    indexedDB.deleteDatabase("backupFiles");

    // Create/open database
    let request = indexedDB.open("backupFiles", dbVersion),
        db,
        createObjectStore = function (dataBase) {
            // Create an objectStore
            console.log("Creating objectStore")
            dataBase.createObjectStore("images");
            dataBase.createObjectStore("structure");
            dataBase.createObjectStore("books");
        },

        getAndStoreImagesAndSchema = async function () {
            let booksTransaction = db.transaction(["books"], "readwrite");
            booksTransaction.objectStore("books").put(books, "bookList");
            let transaction = db.transaction(["structure"], "readwrite");
            transaction.objectStore("structure").put(sheets, "sheetStructure");
            let apolloToken = base64url(localStorage.getItem("apollo-token"));
            for (const sheet of sheets) {
                for (let letterNumber = 0; letterNumber < sheet.numberOfSheets; letterNumber++) {
                    let imageUrl = `https://sheetmusic.martin-dallinger.me/sheet/${sheet.id}/${letterNumber}?token=${apolloToken}`;
                    let id = `${sheet.id}/${letterNumber}`;
                    await downloadAndStoreImage(imageUrl, id);
                }
            }
            db.close();
            resolveOuter();
        },
        downloadAndStoreImage = function (imageUrl, id) {
            return new Promise(resolve => {
                let xhr = new XMLHttpRequest(),
                    blob;

                xhr.open("GET", imageUrl, true);
                // Set the responseType to blob
                xhr.responseType = "blob";

                xhr.addEventListener("load", function () {
                    if (xhr.status === 200) {
                        console.log("Image retrieved");
                        blob = xhr.response;
                        console.log("Blob:" + blob);

                        putImageInDb(blob, id);
                        resolve();
                    }
                }, false);
                // Send XHR
                xhr.send();
            })
        },
        putImageInDb = function (blob, id) {
            console.log("Putting images in IndexedDB");
            let transaction = db.transaction(["images"], "readwrite");
            transaction.objectStore("images").put(blob, id);
        };

    request.onerror = function () {
        console.log("Error creating/accessing IndexedDB database");
    };

    request.onsuccess = function () {
        console.log("Success creating/accessing IndexedDB database");
        db = request.result;

        db.onerror = function () {
            console.log("Error creating/accessing IndexedDB database");
        };

        getAndStoreImagesAndSchema();
    }
    // For future use. Currently only in latest Firefox versions
    request.onupgradeneeded = function (event) {
        createObjectStore(event.target.result);
    };
    });
}