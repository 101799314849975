<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="290"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="mx-2"
            fab
            color="var(--primary)"
            v-bind="attrs"
            v-on="on"
            style="position: fixed; right: 0; bottom: calc(7% + 4rem); z-index: 50"
        >
          <v-icon style="color: white">
            mdi-content-save
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          Start backup now?
        </v-card-title>
        <v-card-text>After backing up your data locally, you can access it when you are offline. This can take several minutes... - Would you like to start now?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="var(--primary)"
              text
              @click="dialog = false"
          >
            No
          </v-btn>
          <v-btn
              color="var(--primary)"
              text
              @click="startBackup()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <div class="text-center">
        <v-dialog
            v-model="loadingDialog"
            hide-overlay
            persistent
            width="300"
        >
          <v-card
              color="primary"
              dark
          >
            <v-card-text>
              Backing up your data
              <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
  </v-row>
</template>

<script>
import storeImages from "../scripts/backup";

import gql from "graphql-tag";
import Swal from "sweetalert2";

export default {
  data () {
    return {
      dialog: false,
      loadingDialog: false
    }
  },
  methods:  {
    async startBackup(){
      let result = await this.$apollo.query({
        query: gql`query getSheets{
              user{
                books{
                  sheets {id, insertionDate, sheetName, sheetNumber, sheetAuthor, url, numberOfSheets}
                  name
                  id
                  color {r,g,b,a}
                  author {email}
                  date
                  instrument
                }
              }
            }`,
        fetchPolicy: "no-cache"
      });
      let sheets = result.data.user.books.flatMap((b)=>b.sheets.map((s)=>{s.bookName = b.name; s.bookId = b.id;return s}));

      let books = result.data.user.books.map((book)=>{
        return {
          id: book.id,
          name: book.name,
          color: book.color,
          author: book.author,
          date: book.date,
          instrument: book.instrument
        }
      })

      this.dialog = false;
      this.loadingDialog = true;
      await storeImages(sheets, books);
      this.loadingDialog = false;
      Swal.fire({
        icon: 'success',
        title: 'Backup successful'
      });
    }
  }
}
</script>