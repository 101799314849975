import Swal from "sweetalert2";


export default function useIndexedDB(handler) {
    return new Promise(resolve => {
        let indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.OIndexedDB || window.msIndexedDB,
            dbVersion = 1.0;
        let request = indexedDB.open("backupFiles", dbVersion),
            db;

        request.onerror = function () {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `Error accessing IndexedDB database`
            })
        };

        request.onsuccess = function () {
            console.log("Success creating/accessing IndexedDB database");
            db = request.result;

            db.onerror = function () {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `Error creating/accessing/requesting IndexedDB database`
                })
            };

            // Interim solution for Google Chrome to create an objectStore. Will be deprecated
            if (db.setVersion) {
                if (db.version !== dbVersion) {
                    var setVersion = db.setVersion(dbVersion);
                    setVersion.onsuccess = function () {
                        handler(db);
                    };
                } else {
                    handler(db);
                }
            } else {
                handler(db);
            }
            db.close();
            resolve();
        }
    })

}