<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="mx-2"
            fab
            color="var(--primary)"
            v-bind="attrs"
            v-on="on"
            style="position: fixed; right: 0; bottom: 7%; z-index: 50"
        >
          <v-icon dark style="color: white">
            mdi-pencil-outline
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
            dark
            color="var(--primary)"
        >
          <v-btn
              icon
              dark
              @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Manage Books</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-list
            three-line
            subheader
        >
          <v-subheader>New Book</v-subheader>
          <AddBookForm v-on:book-change="$emit('book-change')"></AddBookForm>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-subheader>Existing Book</v-subheader>
          <v-layout wrap="true" justify-center align-center style="width: 100vw">
          <AddBookCard
            v-for="book in books"
            :key="book.id"
            :book-id="book.id"
            :color="`rgba(${book.color.r},${book.color.g},${book.color.b},${book.color.a})`"
            :book-name="book.name"
            :manager="book.author.email"
            :created-on="book.date"
            :instrument="book.instrument"
            :is-owner="book.isOwner"
            :isInSet="book.isInSet"
            v-on:book-change="$emit('book-change')"
          ></AddBookCard>
          </v-layout>
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import AddBookForm from "./AddBookForm";
  import AddBookCard from "./AddBookCard";
  import gql from "graphql-tag";
  import {EventBus} from "../event-bus";

  export default {
    name: "AddBookMenu",
    components: {AddBookCard, AddBookForm},
    data () {
      return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      books: []
    }
    },
    methods:{
      async loadPublicBooks(){
        let result = await this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: gql`query {
              user {
                associations {
                  books{
                      id,
                      name,
                      author{email},
                      instrument,
                      date,
                      color{r,g,b,a},
                      isOwner,
                      isInSet
                    }
                  }
                }
              }`
        });
        this.books = result.data.user.associations.flatMap((assoc)=>assoc.books);
        console.log("All books: ",this.books);
      }
    },
    mounted() {
      EventBus.$on('reloadBookInformation', ()=>this.loadPublicBooks());
    }
  }
</script>

<style scoped>

</style>