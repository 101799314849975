<template>
  <v-card
      class="bookCard"
      max-width="344"
      style="min-width: min(20rem, 100vw)"
      outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          {{ instrument }}
        </div>
        <v-list-item-title class="text-h5 mb-1">
          {{ bookName }}
        </v-list-item-title>
        <v-list-item-subtitle>Created on: {{ createdOn }}</v-list-item-subtitle>
        <v-list-item-subtitle>Manager: {{ manager }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
          tile
          size="40"
          :color="color"
      ></v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <router-link :to="'/book/'+bookId" v-if="!offline">
        <v-btn
            outlined
            rounded
            text
        >
          Open
        </v-btn>
      </router-link>
      <v-btn v-else
          outlined
          rounded
          text
          @click="openOfflineBook"
      >
        Open
      </v-btn>
      <v-spacer v-if="showSpacer"></v-spacer>
      <v-btn
          color="red"
          v-if="!offline"
          :class="{'notRight':!showSpacer}"
          outlined
          rounded
          v-on:click="handleRemove"
          text
      >
        Remove
      </v-btn>
    </v-card-actions>
  </v-card>

</template>

<script>
import gql from "graphql-tag";
import Swal from "sweetalert2";

export default {
  name: "BookCard",
  props: {
    bookId: String,
    instrument: String,
    bookName: String,
    createdOn: String,
    manager: String,
    color: String,
    offline: Boolean
  },
  data() {
    return {
      showSpacer: window.innerWidth > 500
    }
  },
  methods: {
    openOfflineBook(){
      this.$router.push({name:"offline", params: {id: this.bookId}})
    },
    async handleRemove() {
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation ($bookId: String!) {
          removeBook(bookId: $bookId)
        }`,
          variables: {
            bookId: this.bookId,
          },
        });
        Swal.fire({
          icon: 'success',
          title: `Successfully removed ${this.bookName}!`,
        }).then(() => this.$emit("book-change"));
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Could not remove that book ...`
        });
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>
.notRight {
  margin-left: 1rem;
}
</style>