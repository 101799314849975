<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12">
        <v-toolbar dark color="var(--primary)">
          <v-toolbar-title>Sign up</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
                prepend-icon="mdi-home-group"
                name="association"
                :label="isInvite ? 'Invitation Id' : 'Association Id'"
                v-model="joinId"
                type="text"
                required
                disabled
            ></v-text-field>
            <v-text-field
                prepend-icon="mdi-account"
                name="email"
                label="E-Mail"
                type="text"
                v-model="email"
                required
            ></v-text-field>
            <v-text-field
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Password"
                type="password"
                v-model="password"
                required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="var(--primary)" @click="handleRegister()">
            <span style="color: white">
              Register
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import Swal from "sweetalert2";
import gql from 'graphql-tag';
import {sha256} from 'js-sha256';
import PasswordValidator from 'password-validator';

const passwordSchema = new PasswordValidator();

passwordSchema
    .is().min(10)                                    // Minimum length 10
    .is().max(100)                                  // Maximum length 100
    .has().uppercase()                              // Must have uppercase letters
    .has().lowercase()                              // Must have lowercase letters
    .has().digits(2)                                // Must have at least 2 digits
    .is().not().oneOf(['P4ssw0rd','P455w0rd', 'Password123']);


export default {
  name: 'Register',
  props: {
    source: String,
  },
  data() {
    return {
      email: "",
      joinId: window.location.href.substring(window.location.href.lastIndexOf('/') + 1),
      password: ""
    }
  },
  computed: {
    isInvite(){return this.$route.name === "invitation"},
    isPasswordValid(){
      return passwordSchema.validate(this.password);
    }
  },
  methods: {
    async handleRegister() {
      if(!this.isPasswordValid) {
        Swal.fire({
          icon: 'warning',
          title: 'Choose a stronger password',
          text: `Your Password must contain at least 10 characters, have uppercase and lowercase letters and at least 2 digits!`,
        });
        return;
      }
      this.$refs.form.validate();

      try {
        if (this.isInvite) {
          const res = await this.$apollo.mutate({
            // Query
            mutation: gql`mutation ($email: String!, $passwordHash: String!, $inviteToken: String!) {
                    registerUserByInviteToken(email: $email, passwordHash: $passwordHash, inviteToken: $inviteToken)
                  }`,
            // Parameters
            variables: {
              email: this.email,
              passwordHash: sha256(this.password),
              inviteToken: this.joinId
            },
          });
          if(res.errors)
            throw new Error("Error registering")
        } else {
          const res = await this.$apollo.mutate({
            // Query
            mutation: gql`mutation ($email: String!, $passwordHash: String!, $joinId: String!) {
                    registerUserByAssociationToken(email: $email, passwordHash: $passwordHash, joinId: $joinId)
                  }`,
            // Parameters
            variables: {
              email: this.email,
              passwordHash: sha256(this.password),
              joinId: this.joinId
            },
          });
          if(res.errors)
            throw new Error("Error registering")
        }
        Swal.fire({
          icon: 'success',
          title: 'Successfully Registered',
          text: `Check your E-Mails to confirm your account!`
        })
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Something went wrong... Check your link/token!`
        });
        throw e;
      }
    }
  },
};
</script>

<style></style>
