<template>
  <v-content style="padding: 0">
    <v-container fluid fill-height>
      <v-layout child-flex>
        <v-data-table
            :headers="computedHeaders"
            :items="sheets"
            :search="search"
            sort-by="sheetNumber"
            class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-toolbar-title>{{ title }}</v-toolbar-title>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
            </v-toolbar>
            <v-text-field
                v-model="search"
                label="Search"
                class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:item.actions="{ item }">
            <div style="display: inline">
              <v-btn
                  small
                  fab
                  color="var(--primary)"
                  v-if="isSmall()"
                  @click="viewItem(item)"
              >
                <v-icon style="color: white">
                  mdi-eye
                </v-icon>
              </v-btn>
              <v-icon
                  v-else
                  small
                  class="mr-2"
                  @click="viewItem(item)"
              >
                mdi-eye
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-layout>
    </v-container>
  </v-content>
</template>
<script>
import {EventBus} from "../event-bus";
import useIndexedDB from "../scripts/dbHandler";
import Swal from "sweetalert2";


export default {
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Number',
        align: 'start',
        sortable: true,
        value: 'sheetNumber',
      },
      {text: 'Name', value: 'sheetName'},
      {text: 'Author', value: 'sheetAuthor'},
      {text: 'Inserted on', value: 'insertionDate'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    sheets: [],
    editedIndex: -1,
  }),
  mounted() {
    EventBus.$emit("reloadHeader");
  },
  computed: {
    computedHeaders() {
      return this.headers.filter((h) => {
        if (window.innerWidth > 600)
          return true;

        return h.text !== "Author" && h.text !== "Inserted on"
      });
    },
    title() {
      if(this.sheets.length === 0)
        return "Loading...";
      return `Contents of ${this.sheets[0].bookName}`;
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    isSmall() {
      return window.innerWidth < 600
    },
    async initialize() {
      await useIndexedDB((db) => {
        try {
          let transaction = db.transaction(["structure"], "readonly");
          transaction.objectStore("structure").get("sheetStructure").onsuccess = (event) => {
            this.sheets = event.target.result.filter(sheet => sheet.bookId === this.$route.params.id);
          };

        } catch (e) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `Database is empty or does not exist! Fix this by logging in and pressing the backup-button!`
          })
        }
      });
    },
    async viewItem(item) {
      this.$store.commit('setPageCount', item.numberOfSheets);
      this.$router.push(`/offline-sheet/${item.id}`);
    },
  }
}
</script>

<style>
.v-data-table-header-mobile {
  display: none !important;
}
</style>