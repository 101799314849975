<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            outlined
            color="var(--primary)"
            v-bind="attrs"
            v-on="on"
            @click="startCamera"
            fab
        >
          <v-icon>mdi-camera</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
            dark
            color="var(--primary)"
        >
          <v-btn
              icon
              dark
              @click="exit"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Scan some sheets</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                text
                @click="save"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <Camera></Camera>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import Camera from "./Camera";
import {EventBus} from "../event-bus";

export default {
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    }
  },
  methods: {
    startCamera() {
      EventBus.$emit('startCamera');
    },
    save() {
      this.dialog = false;
      EventBus.$emit('saveImages')
    },
    exit() {
      this.dialog = false;
      EventBus.$emit('discardImages')
    }
  },
  components: {
    Camera: Camera
  }
}
</script>