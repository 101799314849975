<template>
  <div id="app" class="web-camera-container">
    <div v-show="isCameraOpen && isLoading" class="camera-loading">
      <ul class="loader-circle">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>

    <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
      <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
      <video v-show="!isPhotoTaken" ref="camera" style="max-width:90vw; max-height: 65vh" autoplay></video>
      <canvas v-show="isPhotoTaken" id="photoTaken" style="max-width:90vw; max-height: 65vh" ref="canvas" width="2000" height="1000"></canvas>
    </div>

    <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
      <v-btn
          color="var(--primary)"
          @click="takePhoto"
          v-if="!isPhotoTaken"
          fab
      >
        <v-icon style="color: white">mdi-camera</v-icon>
      </v-btn>
      <div
          v-else
      >
        <v-btn
            color="var(--primary)"
            @click="newPhoto"
            fab
        >
          <v-icon style="color: white">mdi-plus</v-icon>
        </v-btn>
        <v-btn
            style="margin-left: 2rem"
            color="var(--primary)"
            @click="deletePhoto"
            fab
        >
          <v-icon style="color: white">mdi-delete</v-icon>
        </v-btn>
        <v-btn
            style="margin-left: 2rem"
            color="var(--primary)"
            v-if="!isCropped"
            @click="crop"
            fab
        >
          <v-icon style="color: white">mdi-crop</v-icon>
        </v-btn>
      </div>
    </div>
    <div id="bottomLine">
      <img
          v-for="url in this.fileUrls"
          :key="url"
          :src="url"
          style="height: 15vh"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Swal from "sweetalert2";
import {EventBus} from "../event-bus";

export default {
  name: "Camera",
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      files: [],
      fileUrls: [],
      link: '#',
      sheetNr: 0,
      videoWidth: 0,
      videoHeight: 0,
      points: [],
      readImage: null,
      isTouchMode: false,
      isCropped: false
    }
  },
  mounted() {
    this.toggleCamera();
    EventBus.$on('startCamera', () => {
      this.isCameraOpen = true;
      this.createCameraElement();
    });
    EventBus.$on('saveImages', () => {
      this.$store.commit('addNewImages', this.files);
      this.cleanUp()
    });
    EventBus.$on('discardImages', () => {
      this.cleanUp()
    });
  },
  methods: {
    cleanUp() {
      this.files = [];
      this.fileUrls = [];
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
    },
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    createCameraElement() {
      this.isLoading = true;
      this.$store.commit('removeNewImages');

      const constraints = (window.constraints = {
        audio: false,
        video: {facingMode: "environment"}
      });
      navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            if (this.$refs.camera != null)
              this.$refs.camera.srcObject = stream;
          })
          .catch((err) => {
            this.isLoading = false;
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `Could not open your camera - check your permissions`
            });
            console.error(err);
          });
    },

    stopCameraStream() {
      if (this.$refs.camera == null) {
        console.error("Camera-Reference is null!");
        return;
      }

      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach(track => {
        track.stop();
      });
    },

    async takePhoto() {
      this.videoWidth = this.$refs.camera.videoWidth;
      this.videoHeight = this.$refs.camera.videoHeight;
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      this.$refs.canvas.width = this.videoWidth;
      this.$refs.canvas.height = this.videoHeight;

      this.$refs.canvas.onmousedown = (e) => {
        if(!this.isTouchMode)
          this.canvasClick(e)
      };
      this.$refs.canvas.ontouchstart = (e) => {
        this.isTouchMode = true;
        this.canvasClick(e)
      }; //TODO: Fix touch support
      this.$refs.canvas.onmouseup = this.stopDragging;
      this.$refs.canvas.onmouseout = this.stopDragging;
      this.$refs.canvas.onmousemove = this.dragCircle;

      const context = this.$refs.canvas.getContext('2d');
      context.drawImage(this.$refs.camera, 0, 0, this.videoWidth, this.videoHeight);
      let fileUrl = this.$refs.canvas.toDataURL("image/jpeg", 1);
      let blob = await (await fetch(fileUrl)).blob();
      let file = new File([blob], `newSheet-${this.sheetNr++}.jpg`,
          {type: "image/jpeg", lastModified: new Date()});

      this.handleNewImage(file, fileUrl)
    },
    handleNewImage(file, fileUrl) {
      this.isCropped = false;
      this.files.push(file);
      this.fileUrls.push(fileUrl);
      let image = cv.imread(this.$refs.canvas);
      this.readImage = image;
      let edges = new cv.Mat();

      cv.Canny(image, edges, 75, 75 * 3);
      let contours = new cv.MatVector();
      let hierarchy = new cv.Mat();

      cv.findContours(edges, contours, hierarchy, cv.RETR_LIST, cv.CHAIN_APPROX_SIMPLE);

      let cnts = []
      for (let i = 0; i < contours.size(); i++) {
        const tmp = contours.get(i);
        const peri = cv.arcLength(tmp, true);
        let approx = new cv.Mat();

        let result = {
          area: cv.contourArea(tmp),
          points: []
        };

        cv.approxPolyDP(tmp, approx, 0.02 * peri, true);
        const pointsData = approx.data32S;
        for (let j = 0; j < pointsData.length / 2; j++)
          result.points.push({x: pointsData[2 * j], y: pointsData[2 * j + 1]});

        if (result.points.length === 4) cnts.push(result);

      }
      cnts.sort((a, b) => b.area - a.area);
      this.points = this.sortPoints(cnts[0].points)
      this.drawPoints();
    },
    sortPoints(points) {
      let tl = points.sort((a, b) => (a.x + a.y) - (b.x + b.y))[0]
      points.splice(points.indexOf(tl),1);
      let tr = points.sort((a, b) => (this.distBetweenPoints(a, tl, false, true)) - (this.distBetweenPoints(b, tl, false, true)))[0]
      points.splice(points.indexOf(tr),1);
      let bl = points.sort((a, b) => (this.distBetweenPoints(a, tl, true, false)) - (this.distBetweenPoints(b, tl, true, false)))[0]
      points.splice(points.indexOf(bl),1);
      let br = points[0]

      return [tl, tr, br, bl]
    },
    distBetweenPoints(a, b, onlyX = false, onlyY = false) {
      if (onlyX)
        return Math.abs(a.x - b.x)
      else if (onlyY)
        return Math.abs(a.y - b.y)
      else
        return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2))
    },
    canvasClick(e) {
      let pageX = e.pageX ? e.pageX : e.touches[0].pageX
      let pageY = e.pageY ? e.pageY : e.touches[0].pageY

      let x = (pageX - e.target.offsetLeft) / this.$refs.canvas.clientWidth;
      let y = (pageY - e.target.offsetTop) / this.$refs.canvas.clientHeight;

      for (let i = 0; i < this.points.length; i++) {
        if (Math.pow(this.points[i].x / this.videoWidth - x, 2) + Math.pow(this.points[i].y / this.videoHeight - y, 2) < 0.01 && !this.points[i].selected) {
          this.points[i].selected = true;
          console.log("Selected a point!");
          break
        } else {
          if (!this.isTouchMode) {
            this.points[i].selected = false;
          }else if (this.points[i].selected) {
            console.log(`Setting to ${x * this.videoWidth}|${y * this.videoHeight}`)
            this.points[i].x = x * this.videoWidth;
            this.points[i].y = y * this.videoHeight;
            this.points[i].selected = false;
            this.draw();
          }
        }
      }
    },
    drawPoints() {
      let context = this.$refs.canvas.getContext('2d');
      for (let i = 0; i < this.points.length; i++) {
        let circle = this.points[i];

        context.beginPath();
        if (i !== 0) {
          context.fillStyle = "green";
          context.strokeStyle = "green";
        } else {
          context.fillStyle = "red";
          context.strokeStyle = "red";
        }
        context.arc(circle.x, circle.y, 10, 0, Math.PI * 2);
        context.fill();
        context.stroke();

        context.lineWidth = 1;

        context.beginPath();
        context.moveTo(circle.x, circle.y);
        context.lineTo(this.points[i - 1 >= 0 ? i - 1 : 3].x, this.points[i - 1 >= 0 ? i - 1 : 3].y);
        context.stroke();
      }
    },
    dragCircle(e) {
      if (this.isCropped)
        return;
      let selectedX = e.pageX;
      let selectedY = e.pageY;

      for (let i = 0; i < this.points.length; i++)
        if (this.points[i].selected) {
          this.points[i].x = (selectedX - e.target.offsetLeft) / this.$refs.canvas.clientWidth * this.videoWidth;
          this.points[i].y = (selectedY - e.target.offsetTop) / this.$refs.canvas.clientHeight * this.videoHeight;
          break;
        }
      this.draw();
    },
    // eslint-disable-next-line no-unused-vars
    stopDragging(e) {
      if (this.isTouchMode) {
        return
      }
      for (let i = 0; i < this.points.length; i++) {
        this.points[i].selected = false;
      }
    },
    draw() {
      let canvas = this.$refs.canvas;
      let context = canvas.getContext("2d");

      let img = new Image();
      img.onload = () => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(img, 0, 0, this.videoWidth, this.videoHeight);
        this.drawPoints(this.points);
      }
      img.src = this.fileUrls[this.fileUrls.length - 1];
    },
    async crop() {
      const tl = this.points[0], tr = this.points[1], br = this.points[2], bl = this.points[3];

      const width = Math.max(
          Math.sqrt((br.x - bl.x) ** 2 + (br.y - bl.y) ** 2),
          Math.sqrt((tr.x - tl.x) ** 2 + (tr.y - tl.y) ** 2),
      );

      const height = Math.max(
          Math.sqrt((tr.x - br.x) ** 2 + (tr.y - br.y) ** 2),
          Math.sqrt((tl.x - bl.x) ** 2 + (tl.y - bl.y) ** 2),
      );

      const from = cv.matFromArray(4, 1, cv.CV_32FC2, [this.points[0].x, this.points[0].y, this.points[1].x, this.points[1].y, this.points[2].x, this.points[2].y, this.points[3].x, this.points[3].y]);
      const to = cv.matFromArray(4, 1, cv.CV_32FC2, [0, 0, width - 1, 0, width - 1, height - 1, 0, height - 1]);
      const M = cv.getPerspectiveTransform(from, to);
      let afterTransform = new cv.Mat();
      let afterContrast = new cv.Mat();
      let size = new cv.Size();
      size.width = width;
      size.height = height;

      cv.warpPerspective(this.readImage, afterTransform, M, size);
      cv.convertScaleAbs(afterTransform, afterContrast, 1.25, 0);
      cv.imshow(this.$refs.canvas, afterContrast);

      let urlToEdit = this.$refs.canvas.toDataURL("image/jpeg", 1);

      let canvasOptions = {
        useWorker:false, //Whether to use Web Worker to do the processing or not
      }
      let fx = new CanvasEffects(this.$refs.canvas, canvasOptions); //Initlize the CanvasEffect instance
      fx.load(urlToEdit, function(){
        this.whiteBalance(5000)
        this.greyscale()
        this.contrast(12)
      });

      this.isCropped = true;
      let fileUrl = this.$refs.canvas.toDataURL("image/jpeg", 1);
      let blob = await (await fetch(fileUrl)).blob();
      let file = new File([blob], `newSheet-${this.sheetNr++}.jpg`,
          {type: "image/jpeg", lastModified: new Date()});

      this.files.pop();
      this.fileUrls.pop();
      this.files.push(file);
      this.fileUrls.push(fileUrl);

      this.points = [];
    },
    deletePhoto() {
      this.isPhotoTaken = !this.isPhotoTaken;
      this.files.pop();
      this.fileUrls.pop();
      this.isCropped = false;
    },
    newPhoto() {
      this.isCropped = false;
      this.isPhotoTaken = !this.isPhotoTaken;
    }
  }
}
</script>

<style scoped>
#bottomLine {
  bottom: 0;
  position: fixed;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: scroll;
  scroll-behavior: smooth;
  width: 100vw;
  height: 15vh;
  background-color: #383838;
}

.web-camera-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.web-camera-container .camera-button {
  margin-bottom: 2rem;
}

.web-camera-container .camera-box .camera-shutter {
  opacity: 0;
  height: calc(100vh - 64px);
  background-color: #fff;
  position: absolute;
}

.web-camera-container .camera-box .camera-shutter.flash {
  opacity: 1;
}

.web-camera-container .camera-loading {
  overflow: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  min-height: 150px;
  margin: 3rem 0 0 -1.2rem;
}

.web-camera-container .camera-loading ul {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 999999;
  margin: 0;
}

.web-camera-container .camera-loading .loader-circle {
  display: block;
  height: 14px;
  margin: 0 auto;
  top: 50%;
  left: 100%;
  position: absolute;
  width: 100%;
  padding: 0;
}

.web-camera-container .camera-loading .loader-circle li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  padding: 0;
  position: relative;
  margin: 0 0 0 4px;
  background: #999;
  animation: preload 1s infinite;
  top: -50%;
  border-radius: 100%;
}

.web-camera-container .camera-loading .loader-circle li:nth-child(2) {
  animation-delay: 0.2s;
}

.web-camera-container .camera-loading .loader-circle li:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes preload {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

</style>