<template>
  <v-card
      class="bookCard"
      max-width="344"
      style="margin: 1rem"
      outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          {{ instrument }}
        </div>
        <v-list-item-title class="text-h5 mb-1">
          {{ bookName }}
        </v-list-item-title>
        <v-list-item-subtitle>Created on: {{createdOn}}</v-list-item-subtitle>
        <v-list-item-subtitle>Manager: {{manager}}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
          tile
          size="40"
          :color="color"
      ></v-list-item-avatar>
    </v-list-item>

    <v-card-actions >
      <v-btn
          outlined
          rounded
          text
          v-show="isInSet"
          @click="handleRemove"
      >
        Remove
      </v-btn>
      <v-btn
          outlined
          rounded
          text
          v-show="!isInSet"
          @click="handleAdd"
      >
        Add
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
          color="red"
          outlined
          rounded
          v-show="isOwner"
          @click="handleDeleteForever"
          text
      >
        Delete forever
      </v-btn>
    </v-card-actions>
  </v-card>

</template>

<script>
import gql from "graphql-tag";
import Swal from "sweetalert2";

export default {
  name: "AddBookCard",
  props:{
    bookId: String,
    instrument: String,
    bookName: String,
    createdOn: String,
    manager: String,
    color: String,
    isOwner: Boolean,
    isInSet: Boolean
  },
  methods:{
    async handleAdd() {
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation ($bookId: String!) {
          acquireBook(bookId: $bookId)
        }`,
          variables: {
            bookId: this.bookId,
          },
        });
        Swal.fire({
          icon: 'success',
          title: `Successfully added ${this.bookName}!`,
        }).then(()=>this.$emit("book-change"));
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Could not add that book ...`
        });
        console.log(e);
      }
    },
    async handleRemove() {
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation ($bookId: String!) {
          removeBook(bookId: $bookId)
        }`,
          variables: {
            bookId: this.bookId,
          },
        });
        Swal.fire({
          icon: 'success',
          title: `Successfully removed ${this.bookName}!`,
        }).then(()=>this.$emit("book-change"));
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Could not remove that book ...`
        });
        console.log(e);
      }
    },
    async handleDeleteForever() {
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation ($bookId: String!) {
          permanentlyDeleteBook(bookId: $bookId)
        }`,
          variables: {
            bookId: this.bookId,
          },
        });
        Swal.fire({
          icon: 'success',
          title: `Successfully deleted ${this.bookName} forever!`,
        }).then(()=>this.$emit("book-change"));
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Could not remove that book ...`
        });
        console.log(e);
      }
    }

  }
}
</script>

<style scoped>

</style>