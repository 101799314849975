<template>
  <v-content style="padding: 0">
    <v-container fluid fill-height>
      <v-layout child-flex>
        <v-data-table
            :headers="computedHeaders"
            :items="sheets"
            :search="search"
            sort-by="sheetNumber"
            class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-toolbar-title>{{isMobile() ? "" : "Contents of"}} {{ bookName }}</v-toolbar-title>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <MassImport
                :book-id="bookId"
              />
              <v-dialog
                  v-model="dialog"
                  max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="var(--primary)"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                  >
                    New
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                          <v-text-field
                              v-model="editedItem.sheetName"
                              label="Sheet name"
                          ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                          <v-text-field
                              v-model="editedItem.sheetNumber"
                              type="number"
                              label="Sheet number"
                          ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                          <v-text-field
                              v-model="editedItem.sheetAuthor"
                              label="Sheet author"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                            cols="12"
                            sm="2"
                            md="2"
                        >
                          <CameraDialog
                              style="margin-top: .15rem"
                              v-if="formTitle === 'New Sheet' && !uploaded"
                              :uploaded="uploaded"
                          ></CameraDialog>
                          <v-btn
                              outlined
                              color="red"
                              disabled
                              fab
                              v-else
                          >
                            <v-icon style="color: white">mdi-camera</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="10"
                            md="10"
                        >
                          <v-file-input
                              counter
                              multiple
                              show-size
                              truncate-length="15"
                              prepend-icon="mdi-cloud-upload"
                              @change="updateFiles"
                              ref="fileInputField"
                              v-if="numberOfTakenImages === 0"
                              filled
                          ></v-file-input>
                          <div class="caption" v-else>
                            You have already taken {{ numberOfTakenImages }}
                            {{ numberOfTakenImages > 1 ? "images" : "image" }} which will be uploaded!
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <div class="caption" v-if="formTitle === 'Edit Sheet'">Warning: Uploading images will remove all
                          other images of this sheet!
                        </div>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="var(--primary)"
                        text
                        @click="close"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        color="var(--primary)"
                        text
                        @click="save"
                        :disabled="!submittable"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-text-field
                v-model="search"
                label="Search"
                class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:item.actions="{ item }">
            <div style="display: inline">
              <v-btn
                  small
                  fab
                  icon
                  color="rgb(200,0,0)"
                  v-if="isSmall()"
                  @click="deleteItem(item)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
              <v-icon
                  v-else
                  small
                  @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </div>
            <div style="display: inline">
              <v-btn
                  class="mx-2"
                  small
                  fab
                  icon
                  v-if="isSmall()"
                  @click="editItem(item)"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                  v-else
              >
                mdi-pencil
              </v-icon>
            </div>
            <div style="display: inline">
              <v-btn
                  small
                  fab
                  color="var(--primary)"
                  v-if="isSmall()"
                  @click="viewItem(item)"
              >
                <v-icon style="color: white">
                  mdi-eye
                </v-icon>
              </v-btn>
              <v-icon
                  v-else
                  small
                  class="mr-2"
                  @click="viewItem(item)"
              >
                mdi-eye
              </v-icon>
            </div>
          </template>
          <template v-slot:no-data>
            <v-btn
                color="var(--primary)"
                style="color: white"
                @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </v-layout>
    </v-container>
  </v-content>
</template>
<script>
import Swal from "sweetalert2";
import gql from 'graphql-tag';
import {EventBus} from "../event-bus";
import CameraDialog from "../components/CameraDialog";
import {mapGetters} from "vuex";
import MassImport from "../components/MassImport";


const MUTATION = gql`
  mutation ($sheetId: String!, $file: [Upload]) {
    uploadImages(sheetId: $sheetId, file: $file)
  }
`;

export default {
  components: {MassImport, CameraDialog},
  data: () => ({
    bookId: String,
    dialog: false,
    dialogDelete: false,
    bookName: '',
    search: '',
    headers: [
      {
        text: 'Number',
        align: 'start',
        sortable: true,
        value: 'sheetNumber',
      },
      {text: 'Name', value: 'sheetName'},
      {text: 'Author', value: 'sheetAuthor'},
      {text: 'Inserted on', value: 'insertionDate'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    sheets: [],
    lastFiles: [],
    editedIndex: -1,
    isEditing: false,
    idBuffer: '',
    editedItem: {
      sheetNumber: '',
      sheetName: '',
      sheetAuthor: '',
      insertionDate: ''
    },
    defaultItem: {
      sheetNumber: '',
      sheetName: '',
      sheetAuthor: '',
      insertionDate: ''
    },
  }),
  mounted() {
    EventBus.$emit("reloadHeader");
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Sheet' : 'Edit Sheet'
    },
    computedHeaders() {
      return this.headers.filter((h) => {
        if (window.innerWidth > 600)
          return true;

        return h.text !== "Author" && h.text !== "Inserted on"
      });
    },
    numberOfTakenImages() {
      return this.getNewImages.length
    },
    submittable() {
      return (this.uploaded || this.isEditing) && this.editedItem.sheetNumber && this.editedItem.sheetAuthor && this.editedItem.sheetName
    },
    uploaded() {
      if(this.getNewImages && this.getNewImages.length > 0)
        return true;

      if (this.lastFiles == null) {
        console.log("LastFiles was null!")
        return false;
      }
      return this.lastFiles.length !== 0;
    },
    ...mapGetters([
      'getNewImages'
    ])
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
    EventBus.$on("reinitBookOverview",()=>this.initialize());
  },
  methods: {
    isMobile(){
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    isSmall() {
      return window.innerWidth < 600
    },
    updateFiles(files) {
      this.lastFiles = files
    },
    uploadFiles() {
      let files = null;
      if (this.getNewImages.length !== 0) {
        files = this.getNewImages;
      } else
        files = this.lastFiles

      if (files.length === 0)
        return

      console.log("Target is:", files)
      this.$apollo.getClient().mutate({mutation: MUTATION, variables: {file: files, sheetId: this.idBuffer}})
          .then((result) => {
            console.log("Result of file-upload: ", result);
          }).catch(e => {
        console.error(e);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Make sure to upload a png/json file`
        });
      });
    },
    async initialize() {
      this.bookId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

      let result = await this.$apollo.query({
        query: gql`query getSheets{
              user{
                books(id:"${this.bookId}"){
                  sheets {id, insertionDate, sheetName, sheetNumber, sheetAuthor, url, numberOfSheets}
                  name
                }
              }
            }`,
        fetchPolicy: "no-cache"
      });
      this.bookName = result.data.user.books[0].name;
      this.sheets = result.data.user.books[0].sheets;
    },
    async viewItem(item) {
      let result = await this.$apollo.query({
        query: gql`query ($bookId:String, $sheetId:String){
                user {
                  books(id:$bookId){
                      sheets(id:$sheetId){
                        numberOfSheets
                      }
                    }
                  }
                }`,
        fetchPolicy: "no-cache",
        variables: {
          bookId: this.bookId,
          sheetId: item.id
        }
      });
      this.$store.commit('setPageCount', result.data.user.books[0].sheets[0].numberOfSheets);
      this.$store.commit('setCurrentBookId', this.bookId);
      this.$router.push(`/sheet/${item.id}`);
    },
    async editItem(item) {
      this.idBuffer = item.id
      this.isEditing = true
      this.editedIndex = this.sheets.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      console.log("Set Id-Buffer to ", item.id);
      this.idBuffer = item.id
      this.editedIndex = this.sheets.indexOf(item)
      this.editedItem = Object.assign({}, item)
      Swal.fire({
        icon: "warning",
        title: 'Are you sure you want to delete this item?',
        showDenyButton: true,
        confirmButtonText: `Delete it!`,
        denyButtonText: `No!`,
      }).then((result) => {
        if (result.isConfirmed)
          this.deleteItemConfirm()
        else
          this.closeDelete();
      })
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation ($bookId:String!, $sheetId:String!) {
                        removeSheet(
                          bookId:$bookId,
                          sheetId:$sheetId
                        )
                      }`,
          variables: {
            bookId: this.bookId,
            sheetId: this.idBuffer
          },
        });
        Swal.fire({
          icon: 'success',
          title: `Successfully removed a sheet from ${this.bookName}!`,
        });
        this.sheets.splice(this.editedIndex, 1)
        this.closeDelete()
        this.close();
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Could not delete sheet - sry...`
        });
        console.log(e);
      }
    },
    close() {
      this.lastFiles = []
      this.dialog = false
      if (this.$refs.fileInputField != null)
        this.$refs.fileInputField.reset();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      });
      this.$store.commit("removeNewImages");
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      if (this.editedIndex === -1) { // Add the sheet:
        try {
          const result = await this.$apollo.mutate({
            mutation: gql`mutation addSheet ($bookId:String!, $sheetName:String!, $sheetNumber:Int!, $sheetAuthor:String){
                        addSheet(bookId:$bookId,
                          sheetInput:
                            {
                              sheetName:$sheetName,
                              sheetNumber:$sheetNumber,
                              sheetAuthor:$sheetAuthor
                            }
                        ){id, insertionDate}
                      }`,
            variables: {
              bookId: this.bookId,
              sheetName: this.editedItem.sheetName,
              sheetNumber: this.editedItem.sheetNumber,
              sheetAuthor: this.editedItem.sheetAuthor
            },
          });
          Swal.fire({
            icon: 'success',
            title: `Successfully added a sheet to ${this.bookName}!`,
          });

          this.editedItem.insertionDate = result.data.addSheet.insertionDate;
          this.editedItem.id = result.data.addSheet.id;
          this.idBuffer = result.data.addSheet.id;
          if (this.editedIndex > -1) {
            Object.assign(this.sheets[this.editedIndex], this.editedItem)
          } else {
            this.sheets.push(this.editedItem)
          }
          this.uploadFiles();

          this.close();
        } catch (e) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `Could not create sheet - sry...`
          });
          console.log(e);
        }
        return;
      }
      this.$apollo.mutate({
        mutation: gql`mutation editSheet($bookId:String!, $sheetId:String!, $sheetName:String!, $sheetNumber:Int!, $sheetAuthor:String){
                          editSheet(
                            bookId:$bookId,
                            sheetId:$sheetId,
                            sheetInput:{
                              sheetName:$sheetName,
                              sheetNumber:$sheetNumber,
                              sheetAuthor:$sheetAuthor
                            }
                          ){
                            sheetName,sheetNumber,sheetAuthor,numberOfSheets
                          }
                        }`,
        variables: {
          bookId: this.bookId,
          sheetId: this.idBuffer,
          sheetName: this.editedItem.sheetName,
          sheetNumber: this.editedItem.sheetNumber,
          sheetAuthor: this.editedItem.sheetAuthor
        },
      }).then(() => {
        Swal.fire({
          icon: 'success',
          title: `Successfully modified a sheet from ${this.bookName}!`,
        });

        Object.assign(this.sheets[this.editedIndex], this.editedItem)
        //this.sheets.splice(this.editedIndex, 1)
        this.uploadFiles();
        this.closeDelete()
        this.close();
      }).catch((e) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Could not modify sheet - sry...`
        });
        console.log(e);
      })
    },
  },
}
</script>

<style>
.v-data-table-header-mobile {
  display: none !important;
}
</style>