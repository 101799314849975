import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router';
import VueRouter from 'vue-router';
import {createProvider} from './vue-apollo'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'
import './registerServiceWorker'
import Toasted from 'vue-toasted';
import '@babel/polyfill';

Vue.use(Vuex)
Vue.use(Toasted)

const store = new Vuex.Store({
    plugins: [
        createPersistedState({
            getState: (key) => Cookies.getJSON(key),
            setState: (key, state) => Cookies.set(key, state, {expires: 3, secure: true})
        })
    ],
    state: {
        pageCount: 0,
        bookId: "",
        newImages: []
    },
    mutations: {
        setPageCount(state, number) {
            state.pageCount = number;
        },
        setCurrentBookId(state, bookId) {
            state.bookId = bookId
        },
        addNewImages(state, images) {
            state.newImages.push(...images);
        },
        removeNewImages(state) {
            state.newImages = []
        }
    },
    getters: {
        getPageCount: state => state.pageCount,
        getBookId: state => state.bookId,
        getNewImages: state => state.newImages
    }
})

Vue.config.productionTip = false
Vue.use(VueRouter);
new Vue({
    vuetify,
    store: store,
    router: router,
    apolloProvider: createProvider(),
    render: h => h(App)
}).$mount('#app')


if ('wakeLock' in navigator) {
    navigator.wakeLock.request()
        .then((wakeLock) => {
            wakeLock.addEventListener('release', () => {
                console.log('Screen Wake Lock released:', wakeLock.released);
            });
        }).catch((err) => {
            console.error(`${err.name}, ${err.message}`);
        });

}