<template>
  <div>
    <div style="display: grid; place-items: center; width: 100vw;">
      <v-layout style="display: block">
        <p class="overline" style="text-align: center">Your Associations</p>
        <div class="list-container">
          <AssociationCard
              v-for="association in this.associations"
              :key="association.id"
              :association-id="association.id"
              :place-name="association.place"
              :number-of-members="association.numberOfMembers"
              :association-name="association.name"
              :free-storage="association.freeStorage"
          />
        </div>
      </v-layout>
      <v-divider style="display: block; width: 80%; margin: 2rem"/>
      <v-layout justify-center align-center wrap="true" style="width: 100vw">
        <p class="overline" style="display: block">Your Books</p>
        <div class="list-container">
          <BookCard :instrument="book.instrument"
                    :created-on="book.date"
                    :book-name="book.name"
                    :offline="false"
                    :manager="book.author.email"
                    :bookId="book.id"
                    :color="`rgba(${book.color.r},${book.color.g},${book.color.b},${book.color.a})`"
                    v-for="book in this.books" :key="book.id"
                    v-on:book-change="loadBooks"
          ></BookCard>
        </div>
      </v-layout>
      <FindBookMenu></FindBookMenu>
      <AddBookMenu v-on:book-change="loadBooks"></AddBookMenu>
      <Backup/>
    </div>
  </div>
</template>

<script>
import BookCard from "../components/BookCard";
import AddBookMenu from "../components/AddBookMenu";
import gql from "graphql-tag";
import {EventBus} from "../event-bus";
import Backup from "../components/Backup";
import AssociationCard from "../components/AssociationCard";
import FindBookMenu from "../components/FindBookMenu";

export default {
  name: "Overview",
  components: {AddBookMenu, BookCard, Backup, AssociationCard, FindBookMenu},
  async created() {
    this.loadBooks(0);
    this.loadAssociations(0);
  },
  data() {
    return {
      books: [],
      associations: []
    }
  },
  methods: {
    async loadBooks(delay = 100) {
      setTimeout(async () => {
        console.log("Reloading all books!");
        let result = await this.$apollo.query({
          query: gql`query {
                user {
                  books{
                      id,
                      name,
                      author{email},
                      instrument,
                      date,
                      color{
                        r,g,b,a
                      }
                  }
                  }
                }`,
          fetchPolicy: "no-cache"
        });
        this.books = result.data.user.books;
        console.log(this.books);
        EventBus.$emit('reloadBookInformation');
      }, delay);
    },
    async loadAssociations(delay = 100) {
      setTimeout(async () => {
        console.log("Reloading all associations!");
        let result = await this.$apollo.query({
          query: gql`query {
                user {
                  associations {
                      id,
                      name,
                      place,
                      numberOfMembers,
                      freeStorage
                    }
                  }
                }`,
          fetchPolicy: "no-cache"
        });
        this.associations = result.data.user.associations;
      }, delay);
    }
  }
}
</script>

<style scoped>
.list-container {
  justify-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
}

.bookCard {
  margin: 1rem;
}
</style>