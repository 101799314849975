<template>
  <v-dialog
      max-width="500px"
      v-model="dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="secondary"
          style="margin-right: 1rem"
          dark
          class="mb-2"
          v-bind="attrs"
          v-on="on"
      >
        Import
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Mass import</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <p>Your filenames need to have the format <b>SheetNumber</b>_<b>Title</b>_<b>Author</b>_<b>PageNumber</b>[.jpg/png]
          </p>
          <v-file-input
              counter
              multiple
              show-size
              truncate-length="15"
              prepend-icon="mdi-cloud-upload"
              @change="updateFiles"
              ref="fileInputField"
              filled
          ></v-file-input>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="var(--primary)"
            text
            @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
            color="var(--primary)"
            text
            @click="upload"
            :disabled="!filesChosen"
            v-if="!isUploading"
        >
          Upload
        </v-btn>
        <v-progress-circular
            v-else
            indeterminate
            color="var(--primary)"
        ></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import gql from "graphql-tag";
import Swal from "sweetalert2";
import {EventBus} from "../event-bus";

const MUTATION = gql`
  mutation ($sheetId: String!, $file: [Upload]) {
    uploadImages(sheetId: $sheetId, file: $file)
  }
`;

export default {
  name: "MassImport",
  data() {
    return {
      lastFiles: [],
      fileGroups: new Map(),
      dialog: false,
      isUploading: false
    }
  },
  props: {
    bookId: String
  },
  computed: {
    filesChosen() {
      return this.lastFiles.length > 0
    }
  },
  methods: {
    async upload() {
      this.generateFileGroups();
      this.isUploading = true;
      for (let fileDescriptionList of this.fileGroups.values()) {
        try {
          let sortedFileDescriptionList = fileDescriptionList.sort((a, b) => a.pageNr - b.pageNr);
          console.log(sortedFileDescriptionList);
          const response = await this.$apollo.mutate({
            mutation: gql`mutation addSheet ($bookId:String!, $sheetName:String!, $sheetNumber:Int!, $sheetAuthor:String){
                        addSheet(bookId:$bookId,
                          sheetInput:
                            {
                              sheetName:$sheetName,
                              sheetNumber:$sheetNumber,
                              sheetAuthor:$sheetAuthor
                            }
                        ){id}
                      }`,
            variables: {
              bookId: this.bookId,
              sheetName: sortedFileDescriptionList[0].title,
              sheetNumber: sortedFileDescriptionList[0].sheetNr,
              sheetAuthor: sortedFileDescriptionList[0].author
            },
          });
          await this.uploadSheet(response.data.addSheet.id, sortedFileDescriptionList.map(fileDescription => fileDescription.file));
        } catch (err) {
          console.error(err)
        }
      }
      this.isUploading = false;

      let numOfSheets = Array.from(this.fileGroups.values()).length;
      Swal.fire({
        icon: 'success',
        title: `Successfully added ${numOfSheets} ${numOfSheets === 1 ? 'sheet' : 'sheets'}!`,
      }).then(()=>{
        EventBus.$emit("reinitBookOverview")
      });

      this.dialog = false;
    },
    generateFileGroups() {
      this.lastFiles.forEach((f) => {
        let [sheetNr, title, author, pageNr] = f.name.split("_");
        if(pageNr)
          pageNr = pageNr.split(".")[0]
        else{
          pageNr = 1
          author = author.split(".")[0]
        }

        const fileId = `${sheetNr}|${title}|${author}`;
        if (!this.fileGroups.get(fileId))
          this.fileGroups.set(fileId, [])
        this.fileGroups.get(fileId).push({
          sheetNr: +sheetNr,
          title: title,
          author: author,
          pageNr: +pageNr,
          file: f
        });
      });
    },
    async uploadSheet(sheetId, files) {
      try{
        const result = await this.$apollo.getClient().mutate({mutation: MUTATION, variables: {file: files, sheetId: sheetId}})
        console.log("Result of file-upload: ", result);
      } catch (e) {
        console.error(e);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Make sure to upload a png/jpg file`
        });
      }
    },
    close() {
      this.lastFiles = []
      if (this.$refs.fileInputField != null)
        this.$refs.fileInputField.reset();
      this.dialog = false;
    },
    updateFiles(files) {
      console.log("in update files", files)
      if (files) {
        this.lastFiles = files
      }
    },
  }
}
</script>

<style scoped>

</style>