import Register from '../views/Register';
import Login from '../views/Login';
import Confirm from '../views/Confirm';
import MyBooks from '../views/MyBooks';
import VueRouter from 'vue-router';
import Book from "../views/Book";
import Sheet from "../views/Sheet";
import Offline from "../views/Offline";
import OfflineSheet from "../views/OfflineSheet";
import OfflineBooks from "../views/OfflineBooks";

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/confirm/*',
        name: 'confirm',
        component: Confirm
    },
    {
        path: '/register/*',
        name: 'register',
        component: Register
    },
    {
        path: '/invitation/*',
        name: 'invitation',
        component: Register
    },
    {
        path: '/my-books',
        name: 'my-books',
        component: MyBooks
    },
    {
        path: '/offline-books',
        name: 'offline-books',
        component: OfflineBooks
    },
    {
        path: '/book/*',
        name: 'book',
        component: Book
    },
    {
        path: '/sheet/*',
        name: 'sheet',
        component: Sheet
    },
    {
        path: '/offline-book/:id',
        name: 'offline',
        component: Offline
    },
    {
        path: '/offline-sheet/*',
        name: 'offline-sheet',
        component: OfflineSheet
    },
    {
        path: '*',
        name: 'catchAll',
        redirect: '/login'
    },
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;