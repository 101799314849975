<template>
  <div style="width: 100vw; height: 100vh; display: grid; place-items: center; z-index: 100;" :class="{ loader: true, fadeout: !isLoading }">
    <img style="height: 40vh" src="/img/icons/eagle.png"/>
    <div>
      Loading
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingScreen",
  props: ["isLoading"]
};
</script>

<style>
.loader {
  background-color: #383838;
  bottom: 0;
  color: white;
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  padding-top: 10vh;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
}

.fadeout {
  animation: fadeout .6s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
