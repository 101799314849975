<template>
  <div class="presenterMode" id="app">
    <v-app id="inspire">
      <v-card>
        <v-navigation-drawer
            v-model="drawer"
            :mini-variant="true"
            v-if="rightNavigation"
            permanent
            right
            fixed
        >
          <v-list-item class="px-2">
            <div style="margin-left: .5rem">
              {{ (this.imageId + 1 + this.dualPage) + "/" + this.getPageCount }}
            </div>
          </v-list-item>
          <v-divider></v-divider>
          <v-list dense>
            <OptionListItem
                icon="mdi-home"
                title="Home"
                @click="homeHandler"
            />
            <OptionListItem
                icon="mdi-arrow-left"
                title="Previous Page"
                @click="previousHandler"
                v-if="imageId > 0"
            />
            <div style="height: 1rem"></div>
            <OptionListItem
                icon="mdi-arrow-right"
                title="Next Page"
                @click="nextHandler"
                v-if="imageId + 1 + this.dualPage < getPageCount"
            />
            <OptionListItem
                icon="mdi-stretch-to-page"
                title="Stretch to page"
                @click="fitImage = !fitImage"
                v-if="!dualPage"
            />
            <OptionListItem
                icon="mdi-arrow-vertical-lock"
                title="Allow vertical scrolling"
                @click="fitHeight = !fitHeight"
                v-if="!dualPage"
            />
            <div style="height: 1rem"></div>
            <OptionListItem
                icon="mdi-flip-horizontal"
                title="Split screen"
                @click="dualPageHandler"
                v-if="getPageCount > 1"
            />
            <OptionListItem
                icon="mdi-rotate-right-variant"
                title="Use different side"
                @click="rightNavigation = !rightNavigation"
            />
            <OptionListItem
                icon="mdi-overscan"
                title="Change full screen"
                @click="changeFullScreen"
            />
            <div style="height: 1rem"></div>
            <OptionListItem
                icon="mdi-chevron-left"
                title="Previous sheet"
                @click="changeSheet(smallerEntry)"
                v-if="smallerEntry"
            />
            <OptionListItem
                icon="mdi-chevron-right"
                title="Next sheet"
                @click="changeSheet(biggerEntry)"
                v-if="biggerEntry"
            />
            <div style="height: 1rem"></div>
            <OptionListItem
                icon="mdi-chevron-double-left"
                title="Previous sheet further"
                @click="changeSheet(skipMoreLowerEntry)"
                v-if="skipMoreLowerEntry"
            />
            <OptionListItem
                icon="mdi-chevron-double-right"
                title="Next sheet further"
                @click="changeSheet(skipMoreHigherEntry)"
                v-if="skipMoreHigherEntry"
            />
          </v-list>
        </v-navigation-drawer>
        <v-toolbar
            style="width: 100vw; position: fixed"
            v-else
            dense>
          <v-toolbar-title>{{ (this.imageId + 1 + this.dualPage) + "/" + this.getPageCount }}</v-toolbar-title>
          <v-divider vertical style="margin-left: 10px; margin-right: 10px;"></v-divider>
          <v-toolbar-title style="color: rgba(0, 0, 0, 0.54)">{{ currentSheetText }}</v-toolbar-title>

          <v-spacer></v-spacer>

          <OptionListItem
              icon="mdi-home"
              title="Home"
              @click="homeHandler"
          />
          <OptionListItem
              icon="mdi-arrow-left"
              title="Previous Page"
              @click="previousHandler"
              v-if="imageId > 0"
          />
          <OptionListItem
              icon="mdi-arrow-right"
              title="Next Page"
              @click="nextHandler"
              v-if="imageId + 1 + this.dualPage < getPageCount"
          />
          <OptionListItem
              icon="mdi-stretch-to-page"
              title="Stretch to page"
              @click="fitImage = !fitImage"
              v-if="!dualPage"
          />
          <OptionListItem
              icon="mdi-arrow-vertical-lock"
              title="Allow vertical scrolling"
              @click="fitHeight = !fitHeight"
              v-if="!dualPage"
          />
          <OptionListItem
              icon="mdi-flip-horizontal"
              title="Split screen"
              @click="dualPageHandler"
              v-if="getPageCount > 1"
          />
          <OptionListItem
              icon="mdi-rotate-right-variant"
              title="Use different side"
              @click="rightNavigation = !rightNavigation"
          />
          <OptionListItem
              icon="mdi-overscan"
              title="Change full screen"
              @click="changeFullScreen"
          />
          <OptionListItem
              icon="mdi-chevron-left"
              title="Previous sheet"
              @click="changeSheet(smallerEntry)"
              v-if="smallerEntry"
          />
          <OptionListItem
              icon="mdi-chevron-right"
              title="Next sheet"
              @click="changeSheet(biggerEntry)"
              v-if="biggerEntry"
          />
          <v-space />
          <OptionListItem
              icon="mdi-chevron-double-left"
              title="Previous sheet further"
              @click="changeSheet(skipMoreLowerEntry)"
              v-if="skipMoreLowerEntry"
          />
          <OptionListItem
              icon="mdi-chevron-double-right"
              title="Next sheet further"
              @click="changeSheet(skipMoreHigherEntry)"
              v-if="skipMoreHigherEntry"
          />
        </v-toolbar>
      </v-card>
      <div :style="{'margin-top': rightNavigation ? '0px' : '56px'}">
        <div :style="{width: innerPageWidth, height: innerPageHeight}" v-if="dualPage">
          <div
              :class="{fitScreen:fitImage, splitContainer: true}"
          >
            <img
                :src="firstImageUrl"
                :style="{height: this.innerPageHeight}"
            />
            <img
                :src="secondImageUrl"
                :style="{height: this.innerPageHeight}"
            />
          </div>
        </div>
        <div :style="{width: innerPageWidth, height: innerPageHeight}" v-else>
          <img v-for="{url, sheetNr} in imageUrls"
               :class="{fitScreen:fitImage, defaultImage:true}"
               :src="url"
               :key="sheetNr"
               v-show="sheetNr === imageId"
          />
        </div>
      </div>
    </v-app>
  </div>
</template>
<script>

import {EventBus} from "../event-bus";
import {mapGetters} from "vuex";
import useIndexedDB from "../scripts/dbHandler";
import OptionListItem from "../components/OptionListItem";
import Swal from "sweetalert2";

export default {
  name: "Sheet",
  components: {OptionListItem},
  async created() {
    this.creationHandler();
  },
  data() {
    return {
      drawer: true,
      mini: true,
      fitImage: true,
      fitHeight: true,
      dualPage: false,
      sheetId: window.location.href.substring(window.location.href.lastIndexOf('/') + 1),
      imageId: 0,
      imageUrls: [],
      rightNavigation: true,
      nextSheet: undefined,
      previousSheet: undefined,
      smallerEntry: undefined,
      biggerEntry: undefined,
      currentSheetText: 'loading...',
      skipMoreHigherEntry: undefined,
      skipMoreLowerEntry: undefined,
    }
  },
  methods: {
    changeSheet(otherSheet){
      this.$store.commit('setPageCount', otherSheet.numberOfSheets);
      this.$router.push(`/offline-sheet/${otherSheet.id}`, this.changedSheet);
    },
    changedSheet(){
      this.sheetId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
      this.creationHandler();
    },
    async creationHandler() {
      EventBus.$emit("reloadHeader");
      this.imageUrls = [];
      this.currentSheetText = "loading...";
      this.getImageUrls();
      this.dualPage = !this.isMobile() &&
          window.innerWidth / window.innerHeight > 1.4 && this.getPageCount > 1;

      await useIndexedDB((db) => {
        try {
          let transaction = db.transaction(["structure"], "readonly");
          transaction.objectStore("structure").get("sheetStructure").onsuccess = (event) => {

            let result =  event.target.result;
            let currentSheet = result.find((sheet)=>sheet.id===window.location.href.substring(window.location.href.lastIndexOf('/') + 1))
            this.currentSheetText = `${currentSheet.sheetName} - ${currentSheet.sheetAuthor} - ${currentSheet.sheetNumber}`;

            this.$store.commit('setCurrentBookId',  currentSheet.bookId);

            this.$toasted.show(this.currentSheetText, {
              theme: "outline",
              position: "bottom-center",
              duration : 2500,
              icon: "note",
              iconPack: "mdi"
            });

            let sameBookSheets = result.filter((r)=>r.bookName === currentSheet.bookName)
            let smallerSheets = sameBookSheets.filter((sheet) => sheet.sheetNumber < currentSheet.sheetNumber).sort((a,b)=>a.sheetNumber - b.sheetNumber);
            let biggerSheets = sameBookSheets.filter((sheet) => sheet.sheetNumber > currentSheet.sheetNumber).sort((a,b)=>a.sheetNumber - b.sheetNumber);

            if(smallerSheets.length > 0)
              this.smallerEntry = smallerSheets[smallerSheets.length-1];

            if(biggerSheets.length > 0)
              this.biggerEntry = biggerSheets[0];

            if(biggerSheets.length > 4) {
              this.skipMoreHigherEntry = biggerSheets[4];
            } else {
              this.skipMoreHigherEntry = undefined;
            }
            if(smallerSheets.length > 3)
              this.skipMoreLowerEntry = smallerSheets[smallerSheets.length - 5];
            else
              this.skipMoreLowerEntry = undefined;
          };
        } catch (e) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `Database is empty or does not exist! Fix this by logging in and pressing the backup-button!`
          })
        }
      });
    },
    homeHandler() {
      this.imageUrls.forEach(({url}) => {
        let URL = window.URL || window.webkitURL;
        URL.revokeObjectURL(url);
      });
      this.$router.push({name:'offline', params: {id: this.getBookId}})
    },
    nextHandler() {
      if (this.imageId + 1 < this.getPageCount) {
        this.imageId++;
      }
    },
    previousHandler() {
      if (this.imageId > 0) {
        this.imageId--;
      }
    },
    dualPageHandler() {
      if (this.imageId + 1 === this.getPageCount && !this.dualPage && this.getPageCount > 1)
        this.imageId -= 1;
      this.dualPage = !this.dualPage
    },
    async getImageUrls() {
      await useIndexedDB((db) => {
        let transaction = db.transaction(["images"], "readonly");

        for (let currentId = 0; currentId < this.getPageCount; currentId++) {
          transaction.objectStore("images").get(`${this.sheetId}/${currentId}`)
              .onsuccess = (event) => {
            let imgFile = event.target.result;
            let URL = window.URL || window.webkitURL;
            this.imageUrls.push({url: URL.createObjectURL(imgFile), sheetNr: currentId});
          };
        }
      });
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    changeFullScreen() {
      if((window.fullScreen) || (window.innerWidth === screen.width && window.innerHeight === screen.height))
        document.exitFullscreen();
      else
        this.enterFullScreen();
      },
    enterFullScreen() {
      console.log(this.skipMoreHigherEntry);
      const docelem = document.documentElement;

      if (docelem.requestFullscreen) {
        docelem.requestFullscreen();
      }
      else if (docelem.mozRequestFullScreen) {
        docelem.mozRequestFullScreen();
      }
      else if (docelem.webkitRequestFullscreen) {
        docelem.webkitRequestFullscreen();
      }
      else if (docelem.msRequestFullscreen) {
        docelem.msRequestFullscreen();
      }
    }
  },
  computed: {
    ...
        mapGetters([
          'getPageCount',
          'getBookId',
        ]),
    firstImageUrl() {
      console.log(this.imageUrls);
      if (this.imageUrls.length > this.imageId)
        return this.imageUrls[this.imageId].url;
      return "";
    },
    secondImageUrl() {
      if (this.imageUrls.length > this.imageId + 1)
        return this.imageUrls[this.imageId + 1].url;
      return "";
    },
    innerPageWidth() {
      return this.rightNavigation ? `calc(100vw - 56px)` : '100vw';
    },
    innerPageHeight() {
      if (!this.fitHeight)
        return `auto`;

      return this.rightNavigation ? '100vh' : `calc(100vh - 56px)`;
    }
  }
}
</script>

<style scoped>
body {
  padding: 0;
  margin: 0;
}

.fitScreen {
  object-fit: contain;
}

.defaultImage {
  height: 100%;
  width: 100%;
}

.splitContainer {
  display: flex;
}

.splitContainer img {
  max-width: 50%;
}

.container {
  margin: 0 !important;
  padding: 0 !important;
}

::-webkit-scrollbar {
  display: none;
}
</style>