<template>
  <v-row justify="center" style="width: 100%">
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="mx-2"
            fab
            color="var(--primary)"
            v-bind="attrs"
            v-on="on"
            style="position: fixed; right: 0; bottom: calc(7% + 8rem); z-index: 50"
        >
          <v-icon dark style="color: white">
            mdi-file-search
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
            dark
            color="var(--primary)"
        >
          <v-btn
              icon
              dark
              @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Find Sheets</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-list
            three-line
            subheader
        >
          <v-subheader>Find Sheet</v-subheader>
          <v-row justify="center" style="padding: 1rem">
            <v-col
                cols="12"
                sm="6"
                md="4"
            >
              <v-text-field
                  v-model="searchword"
                  :counter="20"
                  label="Keyword"
                  required
              ></v-text-field>
            </v-col>
            <v-col
                cols="1"
                md="1"
            >
              <v-btn
                  outlined
                  rounded
                  color="var(--primary)"
                  text
                  @click="search"
              >
                Search
              </v-btn>
            </v-col>
          </v-row>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-subheader>Results</v-subheader>
          <v-layout wrap="true" justify-center align-center style="width: 100%">
            <template
              v-if="sheets.length > 0"
            >
              <v-card
                  max-width="344"
                  style="min-width: min(20rem, 100vw)"
                  v-for="sheet in sheets"
                  :key="sheet.id"
                  outlined
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      {{ sheet.sheetAuthor }}
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      {{ sheet.sheetName }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Sheet number: {{ sheet.sheetNumber }}</v-list-item-subtitle>
                    <v-list-item-subtitle>Book: {{ sheet.book.name }}</v-list-item-subtitle>
                    <v-list-item-subtitle>Amount of sheets: {{ sheet.numberOfSheets }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-card-actions>
                  <v-btn
                      outlined
                      rounded
                      text
                      @click="viewSheet(sheet.id, sheet.numberOfSheets)"
                  >
                    Show
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
            <div style="width: 100%; height: 20rem; display: grid; place-items: center" v-else>
              <div>
              <v-icon size="100">mdi-magnify</v-icon>
                <span class="text-h4" style="color: rgba(0, 0, 0, 0.54); font-size: min(2.125rem, 5vw) !important; ">
                  {{firstUse ? "Search for a sheet" :
                    searching ? "Searching..." : "Nothing found"}}
                </span>
              </div>

            </div>
          </v-layout>
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "FindBookMenu",
  components: {},
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      searchword: '',
      sheets: [],
      searching: false,
      firstUse: true
    }
  },
  methods: {
    async search() {
      this.firstUse = false;
      this.searching = true;
      let result = await this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`query ($searchword: String!){
              user {
                findSheet(searchword:$searchword) {
                      id,
                      url,
                      sheetName,
                      sheetNumber,
                      sheetAuthor,
                      numberOfSheets,
                      book {
                        name
                      }
                    }
                  }
              }`,
        variables: {
          searchword: this.searchword
        }
      });
      this.searching = false;
      this.sheets = result.data.user.findSheet;
      console.log("Found sheets: ", this.sheets);
    },
    viewSheet(sheetId, numberOfSheets){
      this.$store.commit('setPageCount', numberOfSheets);
      this.$store.commit('setCurrentBookId', "online-search");
      this.$router.push(`/sheet/${sheetId}`);
    }
  },
  mounted() {
    if(this.$route.params.showSearch){
      this.dialog = true;
    }
  }
}
</script>

<style scoped>

</style>