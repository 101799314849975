<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md5>
      <v-card class="card elevation-12">
        <v-toolbar dark color="var(--primary)">
          <v-toolbar-title>Sign in</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
                prepend-icon="mdi-account"
                name="email"
                label="E-Mail"
                type="email"
                v-model="email"
                required
            ></v-text-field>
            <v-text-field
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Password"
                type="password"
                v-model="password"
                required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="var(--primary)" @click="handleLogin()" style="color: white">Log in</v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="card elevation-12">
        <v-toolbar dark color="secondary">
          <v-toolbar-title>Offline mode</v-toolbar-title>
        </v-toolbar>
        <v-card-text style="font-size: 1rem">
          Use your downloaded files without an internet connection
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="handleOfflineMode()">Start</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import Swal from "sweetalert2";
import gql from 'graphql-tag';
import {sha256} from 'js-sha256';
import {onLogin, onLogout} from "../vue-apollo";


export default {
  name: 'Login',
  props: {
    source: String,
  },
  data() {
    return {
      email: "",
      password: ""
    }
  },
  methods: {
    async handleLogin() {
      this.$refs.form.validate();

      await onLogout(this.$apollo.getClient());

      try {
        const result = await this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($email: String!, $passwordHash: String!) {
          login(email: $email, passwordHash: $passwordHash) {
            token
          }
        }`,
          // Parameters
          variables: {
            email: this.email,
            passwordHash: sha256(this.password)
          },
        });
        await onLogin(this.$apollo.getClient(), result.data.login.token);
        this.$router.push({name: "my-books"});
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Wrong account data for user ${this.email} - try again!`
        });
        console.log(e);
      }
    },
    handleOfflineMode() {
      this.$router.push({name: "offline-books"});
    }
  }
};
</script>

<style>
.card {
  margin: 3rem 0 3rem 0
}
</style>
