<template>
  <v-app>
    <LoadingScreen style="z-index: 100" :is-loading="isLoading"></LoadingScreen>
    <v-app-bar
        app
        color="secondary"
        dark
        v-if="showHeaderFooter"
    >
      <router-link :to="getMainButtonRoute()" style="text-decoration: none;">
        <div class="d-flex align-center">
          <img src="/img/icons/whiteeagle.png" style="width: 2.5rem"/>
          <div style="margin-left: 1rem;color: #ffffff; font-size: min(1.7rem, 6vw) !important;" class="text-h2" v-if="displaySheetMusic">
            Sheet Music
          </div>
        </div>
      </router-link>
      <v-spacer/>
      <v-btn :style="{'float': 'right', 'display':logoutButtonDisplayed}" @click="logout()" outlined>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
      <v-btn :style="{'float': 'right', 'display':installBtn, 'margin-left': '1rem'}" @click="installer()">
        {{this.isMobile() ? "Install" : "Install Now"}}
      </v-btn>
    </v-app-bar>
    <v-content>
      <v-container fluid fill-height>
        <router-view></router-view>
      </v-container>
    </v-content>
    <v-footer
        v-if="showHeaderFooter"
    >
      <v-col class="text-center">© Martin Dallinger</v-col>
    </v-footer>
  </v-app>
</template>

<script>

import {EventBus} from "./event-bus";
import {onLogout} from "./vue-apollo";
import LoadingScreen from "./components/LoadingScreen";

export default {
  name: 'App',

  mounted() {
    EventBus.$on('reloadHeader', () => this.refreshHeader());
    this.refreshHeader();
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    });
  },
  components: {
    'LoadingScreen': LoadingScreen
  },
  computed: {
    logoutButtonDisplayed() {
      return this.$route.name !== "login" ? "block" : "none";
    },
    displaySheetMusic(){
      return this.windowWidth > 300;
    }
  },
  methods: {
    isMobile(){
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    refreshHeader() {
      console.log("Refreshing header info!");
      this.showHeaderFooter = window.location.href.indexOf("/sheet/") === -1 && window.location.href.indexOf("/offline-sheet/") === -1
    },
    async logout() {
      await onLogout(this.$apollo.getClient());
      this.$router.push({name: "login"});
    },
    getMainButtonRoute() {
      if (this.$route.name === "login")
        return "/login";

      return this.$route.name.startsWith("offline") ? "/offline-books" : "/my-books"
    }
  },
  data: () => ({
    showHeaderFooter: true,
    installBtn: "none",
    installer: undefined,
    isLoading: true,
    windowWidth: window.innerWidth
  }),
  created() {
    let installPrompt;
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      installPrompt = e;
      this.installBtn = "block";
    });

    this.installer = () => {
      this.installBtn = "none";
      installPrompt.prompt();
      installPrompt.userChoice.then(result => {
        if (result.outcome === "accepted")
          console.log("User accepted");
        else
          console.log("User denied");
      });
      installPrompt = null;
    };

    if(localStorage.getItem("firstAccess") == null) {
      this.$toasted.show("💡 Press the install-button for offline use", { 
        theme: "bubble", 
        position: "top-right", 
        duration : 8000
      });
      localStorage.setItem("firstAccess", "False");
    }
  }
};
</script>

<style>
.toasted-container .toasted {
  background-color: #8c0202 !important;
  border-radius: .5rem 0 0 .5rem !important;
  font-family: Arial, Helvetica, sans-serif !important;
}

.top-right {
  right: 0 !important;
}
</style>
