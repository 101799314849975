<template>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="var(--primary)">
                <v-toolbar-title>Confirm your Account</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form ref="form">
                  <v-text-field
                      prepend-icon="mdi-account"
                      name="email"
                      label="E-Mail"
                      type="text"
                      v-model="email"
                      required
                      disabled
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="var(--primary)" @click="handleRegister()">
                  <span style="color: white">
                    Confirm
                  </span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
</template>

<script>
import Swal from "sweetalert2";
import gql from 'graphql-tag';

export default {
  name: 'Confirm',
  props: {
    source: String,
  },
  data() {
    return {
      email: this.getEmail(),
      joinToken: window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    }
  },
  methods:{
    getEmail(){
      let offset = 43
      if(window.location.href.startsWith("http://127.0.0.1"))
        offset -= 20;
      return window.location.href.substring(window.location.href.indexOf("/",offset)+1, window.location.href.lastIndexOf("/"))
    },
    async handleRegister(){
      this.$refs.form.validate();
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation ($email: String!,  $uuid: String!) {
        enableAccount(email: $email, uuid: $uuid)
      }`,
          // Parameters
          variables: {
            email: this.email,
            uuid: this.joinToken
          },
        });
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Wrong account data for user ${this.email} - try again!`
        })
        console.log(e);
        return;
      }
      await this.$router.push({name: 'login'});
    }
  }
};
</script>

<style></style>
